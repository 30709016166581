import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://aced5ebca677502a25815e7ffd34e14b@o4507902073896960.ingest.de.sentry.io/4507987286622288",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});